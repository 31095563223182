require('./bootstrap');

import Analytics from 'analytics'
import googleAnalytics from '@analytics/google-analytics'
import translate from './translate'

// if (process.env.NODE_ENV == "production") {
//     const analytics = Analytics({
//         app: 'rdv-de-carthage',
//         plugins: [
//             googleAnalytics({
//                 measurementIds: ['G-86PK4RLJ8D']
//             })
//         ]
//     })
//     /* Track a page view */
//     analytics.page()
// }
function getFees() {
    $("#subscription-fee").html("");
    $("#accommodation-fee").html("");
    $("#total-fee").html("");
    $("#fee-card-alert").hide();
    $("#fee-card-table").hide();
    $("#fee-card-spinner").show();
    const data = $("form[name=subscription-form").serialize();
    $.ajax({
        type: "post",
        url: event_slug + "/get-fees",
        data: data,
        dataType: "json",
        success: function (response) {
            if (response.fee) {
                $("#subscription-fee").html(response.fee.subscription);
                $("#accommodation-fee").html(response.fee.accommodation);
                $("#transport-fee").html("0 TND");
                $("#commission-fee").html("0 TND");
                if (response.fee.transport)
                    $("#transport-fee").html(response.fee.transport);
                if (response.fee.ateliers)
                    $("#ateliers-fee").html(response.fee.ateliers);
                $("#total-fee").html(response.fee.total);
            }
            $("#fee-card-spinner").hide();
            $("#fee-card-table").show();
        },
        error: function (err) {
            if (err.status == 422) {
                console.log(err.responseJSON.error);
                $("#fee-card-alert").html(err.responseJSON.error ? translate(err.responseJSON.error, document.documentElement.lang) : translate("Please complete the missing fields.", document.documentElement.lang));
            } else {
                console.log(err);
                $("#fee-card-alert").html(translate("A problem has occurred! Please try again later.", document.documentElement.lang));
            }
            $("#fee-card-spinner").hide();
            $("#fee-card-alert").show();
        },
    });
}
/**
 * @params {File[]} files Array of files to add to the FileList
 * @return {FileList}
 */
function FileListItems(files) {
    var b = new ClipboardEvent("").clipboardData || new DataTransfer()
    for (var i = 0, len = files.length; i < len; i++) b.items.add(files[i])
    return b.files
}
var hotelOfferAssigned = false;
const attachmentsInput = document.getElementById('attachments');
const getFiles = (el) => {
    if (el.files) {
        let files = [];
        $.each(el.files, function (indexInArray, valueOfElement) {
            files.push(`<div data-index=${indexInArray} class="position-relative btn btn-secondary m-2">${el.files[indexInArray].name}<button type="button" class="btn file-preview-close text-white">X</button></div>`)
        });
        $("#files-container").html(files.join('\n'));
    } else {
        $("#files-container").html(``);
    }
}
$(function () {
    $("form[name=slots-form] select[name=room_id]").change(function (e) {
        $("form[name=slots-form] select[name=slot_id] option").hide()
        $("form[name=slots-form] select[name=slot_id]").attr('disabled', true)
        if (e.target.value) {
            $("form[name=slots-form] select[name=slot_id]").attr('disabled', false)
            $(`form[name=slots-form] select[name=slot_id] option[data-room=${e.target.value}]`).show()
        }
    });
    $("form[name=slots-form] select[name=slot_id]").change(function (e) {
        $("form[name=slots-form] input[type=file]").attr('disabled', true)
        if (e.target.value) {
            $("form[name=slots-form] input[type=file]").attr('disabled', false)
        }
    });
    $("#get-fees-btn").click(function (e) {
        e.preventDefault();
        getFees()
    });
    $("#attachments").css("opacity", "0");
    if (attachmentsInput)
        getFiles(attachmentsInput)
    $("#file-browser").click(function (e) {
        e.preventDefault();
        $("#attachments").trigger("click");
    });
    $("#attachments").change(function (e) {
        getFiles(e.target)
    });
    $(document).on('click', 'button.file-preview-close', function (e) {
        e.preventDefault();
        let index = $(e.currentTarget).parent().data('index')
        if (attachmentsInput.files) {
            const fileListArr = Array.from(attachmentsInput.files)
            fileListArr.splice(index, 1) // here u remove the file
            attachmentsInput.files = new FileListItems(fileListArr)// this will trigger a change event
            getFiles(attachmentsInput)
        }
    });

    $("form[name=slots-form]").submit(function (e) {
        e.preventDefault()
        let size = 0;
        const attachments = document.getElementById("attachments")
        let data = new FormData();
        if (attachments.files) {
            $.each(attachments.files, function (indexInArray, valueOfElement) {
                size += attachments.files[indexInArray].size
                data.append(`attachments[${indexInArray}]`, attachments.files[indexInArray])
            });
        }
        data.append("_token", $("input[name=_token]").val())
        data.append("event_id", $("input[name=event_id]").val())
        data.append("slot_id", $("input[name=slot_id]").val())
        data.append("accepts_share", $("input[name=accepts_share]").val())
        data.append("notes", $("textarea[name=notes]").val())
        if (!data.get('notes') && attachments.files.length == 0)
            return
        $("#progress-bar").hide();
        $("#progress-bar .progress-bar").css("width", "0%");
        $("#progress-bar .progress-bar").attr("aria-valuenow", 0);
        $("#progress-bar .progress-bar").html("0%");
        let status = 0;
        var progress = 0;
        $("#progress-bar").show();
        var uploading = setInterval(function () {
            status = Math.round((progress / size) * 100)
            $("#progress-bar .progress-bar").css("width", status + "%");
            $("#progress-bar .progress-bar").attr("aria-valuenow", status);
            $("#progress-bar .progress-bar").html(status + "%");
        }, 500);
        $("form[name=slots-form] button[type=submit]").html(`<div class="spinner-border text-white" role="status"></div>`);
        $("#attachments").removeClass("is-invalid");
        axios({
            method: "POST",
            url: `/dashboard/slot`,
            data: data,
            headers: { "Content-Type": "multipart/form-data" },
            onUploadProgress: progressEvent => progress = progressEvent.loaded
        })
            .then(function ({ data }) {
                //handle success
                $("#content").hide();
                $("#results .alert-danger").hide();
                $("#results .alert-success").show();
                $("#results").show();
            })
            .catch(function ({ response }) {
                if (response.status == 422) {
                    $("#attachments").addClass("is-invalid");
                } else {
                    //handle error
                    $("#results .alert-danger").show();
                    $("#results .alert-success").hide();
                    $("#results").show();
                }
                console.error(response);
            })
            .finally(() => {
                $("#progress-bar").hide();
                clearInterval(uploading);
                $("form[name=slots-form] button[type=submit]").html("Valider")
            })
    });
    if ($("select[name=hotel_id] option:selected").val()) {
        $(`.form-item-wrapper.hotel-offer-list[data-hotel=${$("select[name=hotel_id]").val()}]`).show();
        $(`.form-item-wrapper.hotel-offer-list`).removeClass("active");
        $(`.form-item-wrapper.hotel-offer-list[data-hotel=${$("select[name=hotel_id]").val()}]`).addClass("active");
    }

    $("select[name=hotel_id]").change(function (e) {
        $(`.form-item-wrapper.hotel-offer-list`).hide();
        $(`.form-item-wrapper.hotel-offer-list`).removeClass("active");
        $("input[type=hidden][name=hotel_offer_id]").val("");
        if (e.target.value) {
            $(`.form-item-wrapper.hotel-offer-list[data-hotel=${e.target.value}]`).show();
            $(`.form-item-wrapper.hotel-offer-list[data-hotel=${e.target.value}]`).addClass("active");
        }
    });
    window.addEventListener('message', function (event) {
        if (event.data.event_id === 'paymee.complete') {
            //Execute Step 3
            window.location.replace(window.location.href + "/result?token=" + event.data.payment_token);
        }
    }, false);
});